.container {
  background-color: "#163764";
  width: 100vw;
  height: 100vh;
}
.circle {
  position: absolute;
  margin: auto;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
}
.circle:nth-of-type(1) {
  width: 300px;
  height: 270px;
  animation: rt 2s infinite linear;
  box-shadow: 0 0 1px 0 blueviolet, inset 0 0 10px 0 blueviolet;
}
.circle:nth-of-type(2) {
  width: 270px;
  height: 300px;
  animation: rt 3s infinite linear;
  box-shadow: 0 0 1px 0 darkviolet, inset 0 0 10px 0 darkviolet;
}
.circle:nth-of-type(3) {
  width: 315px;
  height: 285px;
  animation: rt 1s infinite linear;
  box-shadow: 0 0 1px 0 darkmagenta, inset 0 0 10px 0 darkmagenta;
}
.circle:nth-of-type(4) {
  width: 285px;
  height: 315px;
  animation: rt 4s infinite linear;
  box-shadow: 0 0 1px 0 magenta, inset 0 0 10px 0 magenta;
}

@keyframes rt {
  100% {
    transform: rotate(360deg);
  }
}
