.services-slider .swiper-slide {
  height: auto !important;
}
.services-slider .swiper-wrapper {
  align-items: stretch !important;
}

.services-slider .swiper-pagination {
  position: relative;
  margin-top: 1rem;
}

.services-slider .swiper-pagination-bullet {
  background-color: white;
  width: 32px;
  height: 6px;
  border-radius: 9px;
}

.services-slider .swiper-pagination-bullet-active {
  background-color: #00aeef !important;
}

.services-slider .swiper-button-next,
.services-slider .swiper-button-prev {
  position: absolute;
  background-color: #00000030;
  color: #00aeef;
  z-index: 1000;
  border-radius: 12px;
  height: 72px;
  transform: translateY(-50%);
  transition: all ease-in 300ms;
}

.swiper-button-prev::after {
  font-size: 26px;
}
.swiper-button-next::after {
  font-size: 26px;
}

.swiper-button-next:hover {
  background-color: #000000a1;
}
.swiper-button-prev:hover {
  background-color: #000000a1;
}

@media (min-width: 992px) {
  .services-slider .swiper-pagination {
    bottom: 15%;
  }
}

@media (min-width: 1440px) {
  .services-slider .swiper-pagination {
    /* position: relative; */
    margin-top: 0rem;
  }
}
.service-item {
  position: relative;
  overflow: hidden;
  min-height: 400px;
  background-size: cover;
  background-position: center;
  border-radius: 21px;
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  box-shadow: inset 0 -60px 40px -30px rgba(0, 0, 0, 0.75); /* Adds a dark shadow effect at the bottom */
}

.service-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6)); /* Black gradient overlay */
  z-index: 1; /* Ensure it's above the background but below the text */
  border-radius: 21px;
}

.service-item .service-info {
  position: relative;
  bottom: 57px;
  width: 100%;
 
  transition: transform 0.4s ease-in-out;
  z-index: 2; /* Ensures the text is above the overlay */
}

.service-item ul {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
  list-style: none;
  padding-left: 0;
  margin-bottom: 65px;
  position: absolute;
  bottom: 0;
  width: 92%;
 
  z-index: 2;
}

.service-item:hover .service-info {
  transform: translateY(-120px); /* Move title up, ensuring space between the title and list */
}

.service-item:hover ul {
  opacity: 1;
  transform: translateY(0);
}

.service-item ul li {
  padding: 5px 0;
  color: white;
}

.service-item img {
  height: 100%;
  object-fit: cover;
  border-radius: 21px;
}
.read-more-button {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
  position: absolute;
  bottom: 20px; /* Positioned above the ul */
  width: 100%;
 
  z-index: 2;
}

.service-item:hover .read-more-button {
  opacity: 1;
  transform: translateY(0);
}

.read-more-button a {
  text-decoration: none;
  color: white;
  background-color: black;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.read-more-button a:hover {
  background-color: #555;
}

.service-item img {
  height: 100%;
  object-fit: cover;
  border-radius: 21px;
}