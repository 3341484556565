.heroSlider {
  width: 100%;
  height: 100%;
}

.heroSlider {
  min-height: 100% !important;
}

.heroSlider .swiper-pagination {
  bottom: 3% !important;
}

.heroSlider .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #00aeef !important;
  width: 54px;
  height: 8px;
  border-radius: 11px;

  background-color: hsla(0, 0%, 100%, 0.2);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
}

.heroSlider .swiper-pagination-bullet-active {
  background-color: #00aeef !important;
}

.zoom {
  transition: transform 0.5s ease;
}

.zoom.zoomed {
  animation: zoomInOut 5s alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.hero-section-about {
  /* height: 60dvh; */
  width: 100%;
}

.content-parent-about {
  /* height: 60dvh; */
  width: 100%;
}

.image-parent-about {
  /* height: 60dvh; */
  width: 100%;
}

.hero-image-about {
  /* height: 60dvh; */
  width: 100%;
  object-fit: cover;
}
@media (min-width: 992px) {
  .hero-section-about {
    /* height: 80dvh; */
    width: 100%;
  }

  .content-parent-about {
    /* height: 80dvh; */
    width: 100%;
  }

  .image-parent-about {
    /* height: 80dvh; */
    width: 100%;
  }

  .hero-image-about {
    /* height: 80dvh; */
    width: 100%;
    object-fit: cover;
  }
}
@media(max-width:500px){
  .hero-section-about {
    height: 60dvh;
    width: 100%;
  }
}