.partner-slider .swiper-slide {
  height: auto !important;
}
.partner-slider .swiper-wrapper {
  align-items: stretch !important;
}

.partner-slider .swiper-pagination {
  position: relative;
  margin-top: 2.4rem;
}

.partner-slider .swiper-pagination-bullet {
  background-color: #717477;
  width: 32px;
  height: 6px;
  display:none!important;
  border-radius: 9px;
}

.partner-slider .swiper-pagination-bullet-active {
  background-color: #00aeef !important;
  display:none!important;
}

.partner-slider .swiper-button-next,
.partner-slider .swiper-button-prev {
  position: absolute;
  background-color: #00000030;
  color: #00aeef;
  z-index: 1000;
  border-radius: 12px;
  height: 72px;
  transform: translateY(-50%);
  transition: all ease-in 300ms;
}

.swiper-button-prev::after {
  font-size: 26px;
}
.swiper-button-next::after {
  font-size: 26px;
}

.swiper-button-next:hover {
  background-color: #000000a1;
}
.swiper-button-prev:hover {
  background-color: #000000a1;
}
.view-all-text{
  color:white;
  text-align: center;
}