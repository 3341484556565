@tailwind base;
@tailwind components;
@tailwind utilities;

.background-image {
  /* background-size: fill; */
  background-size: fill;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  animation: zoomInOut 8s infinite alternate; /* Adjust timing as needed */
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04); /* Adjust scale for zoom effect */
  }
  100% {
    transform: scale(1);
  }
}

.services-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

html {
  /* overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; */
}

body {
  /* overflow-y: visible;
  position: relative;
  overflow-x: hidden; */
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
}

.partner-bg {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.value-bg {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.award-bg {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.partner-bg {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.services-bg {
  /* height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: s */
  background-size: contain;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100vh;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.home-bg {
  /* height: 200vh; */
  background-image: url("./assests/home-bg.jpg");
  background-position: bottom;
  background-size: cover;
}
