.banner-wrapper {
  position: relative;
  overflow: hidden;
}

.banner-image, .banner-image-hover {
  width: 100%;
  transition: opacity 0.4s ease-in-out;
}

.hidden {
  /* opacity: 0; */
}

.block {
  opacity: 1;
}
