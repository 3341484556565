@keyframes shimmer {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 400% 0;
  }
}

.shimmer {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 70% 100%;
  animation: shimmer 1s;
}

.feature-card {
  position: relative;
  overflow: hidden;
}

.feature-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  transition: opacity 0.3s;
  opacity: 0;
}

.feature-card:hover::after {
  opacity: 1;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 70% 100%;
  animation: shimmer 1s;
  background-repeat: no-repeat;
}
